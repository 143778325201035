import { BalanceTypeEnum } from "@boints/types";

export enum CurrencyEnum {
  CURRENCY_TYPE_BOINTS = 1,
  CURRENCY_TYPE_FIAT = 2,
  CURRENCY_TYPE_PENDING = 3,
}

export const currencyMapper: { [key: string]: string } = {
  [CurrencyEnum.CURRENCY_TYPE_BOINTS]: "Boints",
  [CurrencyEnum.CURRENCY_TYPE_FIAT]: "USD",
  [CurrencyEnum.CURRENCY_TYPE_PENDING]: "Pending",
}

export const BalanceMapper: {[key: number]: string} = {
  [BalanceTypeEnum.BALANCE_TYPE_ADD_PER_AD]: 'BALANCE_TYPE_ADD_PER_AD',
  [BalanceTypeEnum.BALANCE_TYPE_ADD_PER_TAPJOY]: 'BALANCE_TYPE_ADD_PER_TAPJOY',
  [BalanceTypeEnum.BALANCE_TYPE_ADD_PER_FYBER]: 'BALANCE_TYPE_ADD_PER_FYBER',
  [BalanceTypeEnum.BALANCE_TYPE_ADD_PER_IRON_SOURCE]:
    'BALANCE_TYPE_ADD_PER_IRON_SOURCE',
  [BalanceTypeEnum.BALANCE_TYPE_ADD_PER_POLLFISH_VIA_SURVEY]:
    'BALANCE_TYPE_ADD_PER_POLLFISH_VIA_SURVEY',
  [BalanceTypeEnum.BALANCE_TYPE_ADD_PER_POLLFISH_VIA_OFFERWALL]:
    'BALANCE_TYPE_ADD_PER_POLLFISH_VIA_OFFERWALL',
  [BalanceTypeEnum.BALANCE_TYPE_ADD_PER_REVU_VIA_OFFER]:
    'BALANCE_TYPE_ADD_PER_REVU_VIA_OFFER',
  [BalanceTypeEnum.BALANCE_TYPE_ADD_PER_REVU_VIA_CUSTOMER]:
    'BALANCE_TYPE_ADD_PER_REVU_VIA_CUSTOMER',
  [BalanceTypeEnum.BALANCE_TYPE_ADD_PER_MAX]: 'BALANCE_TYPE_ADD_PER_MAX',
  [BalanceTypeEnum.BALANCE_TYPE_ADD_PER_ADJOE]: 'BALANCE_TYPE_ADD_PER_ADJOE',
  [BalanceTypeEnum.BALANCE_TYPE_WITHDRAWAL]: 'BALANCE_TYPE_WITHDRAWAL',
  [BalanceTypeEnum.BALANCE_TYPE_REDUCE_PER_REVU_VIA_OFFER_REVERTED]:
    'BALANCE_TYPE_REDUCE_PER_REVU_VIA_OFFER_REVERTED',
  [BalanceTypeEnum.BALANCE_TYPE_REDUCE_PER_REVU_VIA_CUSTOMER_REVERTED]:
    'BALANCE_TYPE_REDUCE_PER_REVU_VIA_CUSTOMER_REVERTED',
  [BalanceTypeEnum.BALANCE_TYPE_WITHDRAWAL_BONUS]: 'BALANCE_TYPE_WITHDRAWAL_BONUS',
  [BalanceTypeEnum.BALANCE_TYPE_SPIN_THE_WHEEL]: 'BALANCE_TYPE_SPIN_THE_WHEEL',
  [BalanceTypeEnum.BALANCE_TYPE_ADD_PER_AYE]: 'BALANCE_TYPE_ADD_PER_AYE',
  [BalanceTypeEnum.BALANCE_TYPE_ADD_PER_ADGEM]: 'BALANCE_TYPE_ADD_PER_ADGEM',
  [BalanceTypeEnum.BALANCE_TYPE_REDUCE_PER_AYE]: 'BALANCE_TYPE_REDUCE_PER_AYE',
  [BalanceTypeEnum.BALANCE_TYPE_ADD_COMPLETED_PER_INBRAIN]:
    'BALANCE_TYPE_ADD_COMPLETED_PER_INBRAIN',
  [BalanceTypeEnum.BALANCE_TYPE_ADD_DISQUALIFIED_PER_INBRAIN]:
    'BALANCE_TYPE_ADD_DISQUALIFIED_PER_INBRAIN',
  [BalanceTypeEnum.BALANCE_TYPE_WITHDRAWAL_DECLINED]:
    'BALANCE_TYPE_WITHDRAWAL_DECLINED',
  [BalanceTypeEnum.BALANCE_TYPE_REFERRAL_BONUS]: 'BALANCE_TYPE_REFERRAL_BONUS',
  [BalanceTypeEnum.BALANCE_TYPE_COMPLETE_PROFILE]: 'BALANCE_TYPE_COMPLETE_PROFILE',
  [BalanceTypeEnum.BALANCE_TYPE_DAILY_TOURNAMENT]: 'BALANCE_TYPE_DAILY_TOURNAMENT',
  [BalanceTypeEnum.BALANCE_TYPE_TEAM_TOURNAMENT]: 'BALANCE_TYPE_TEAM_TOURNAMENT',
  [BalanceTypeEnum.BALANCE_TYPE_ADJOE_BONUS]: 'BALANCE_TYPE_ADJOE_BONUS',
  [BalanceTypeEnum.BALANCE_TYPE_ADD_PER_TEST_OFFERWALL]:
    'BALANCE_TYPE_ADD_PER_TEST_OFFERWALL',
  [BalanceTypeEnum.BALANCE_TYPE_ADD_BY_ADMIN]: 'BALANCE_TYPE_ADD_BY_ADMIN',
  [BalanceTypeEnum.BALANCE_TYPE_REDUCE_BY_ADMIN]: 'BALANCE_TYPE_REDUCE_BY_ADMIN',
  [BalanceTypeEnum.BALANCE_TYPE_DOUBLE_BOINTS]: 'BALANCE_TYPE_DOUBLE_BOINTS',
  [BalanceTypeEnum.BALANCE_TYPE_FROM_PENDING_BALANCE]:
    'BALANCE_TYPE_FROM_PENDING_BALANCE',
  [BalanceTypeEnum.BALANCE_TYPE_ADD_RANK_REWARD_PER_INBRAIN]:
    'BALANCE_TYPE_ADD_RANK_REWARD_PER_INBRAIN',
  [BalanceTypeEnum.BALANCE_TYPE_EARNED_BONUS]: 'BALANCE_TYPE_EARNED_BONUS',
  [BalanceTypeEnum.BALANCE_TYPE_MOTIVATIONAL_CURRENCY_EARNED]: 'BALANCE_TYPE_MOTIVATIONAL_CURRENCY_EARNED',
  [BalanceTypeEnum.BALANCE_TYPE_HRS_LEVEL_BASED]: 'BALANCE_TYPE_HRS_LEVEL_BASED',
  [BalanceTypeEnum.BALANCE_TYPE_HRS_TIME_BASED]: 'BALANCE_TYPE_HRS_TIME_BASED',
  [BalanceTypeEnum.BALANCE_TYPE_HRS_IAP_BASED]: 'BALANCE_TYPE_HRS_IAP_BASED',
  [BalanceTypeEnum.BALANCE_TYPE_HRS_VIDEO_BASED]: 'BALANCE_TYPE_HRS_VIDEO_BASED',
  [BalanceTypeEnum.BALANCE_TYPE_ADD_PER_MAF]: 'BALANCE_TYPE_ADD_PER_MAF',
  [BalanceTypeEnum.BALANCE_TYPE_ADD_PER_OFFERTORO]: 'BALANCE_TYPE_ADD_PER_OFFERTORO',
  [BalanceTypeEnum.BALANCE_TYPE_HRS_DAILY_REWARD]: 'BALANCE_TYPE_HRS_DAILY_REWARD',
  [BalanceTypeEnum.BALANCE_TYPE_ADD_PER_ADGATE]: 'BALANCE_TYPE_ADD_PER_ADGATE',
  [BalanceTypeEnum.BALANCE_TYPE_ADD_PER_FARLY]: 'BALANCE_TYPE_ADD_PER_FARLY',
  [BalanceTypeEnum.BALANCE_TYPE_ADD_PER_ADBREAK]: 'BALANCE_TYPE_ADD_PER_ADBREAK',
  [BalanceTypeEnum.BALANCE_TYPE_ADD_PER_BITLABS]: 'BALANCE_TYPE_ADD_PER_BITLABS',
  [BalanceTypeEnum.BALANCE_TYPE_ADD_PER_APPSAMURAI]: 'BALANCE_TYPE_ADD_PER_APPSAMURAI',
  [BalanceTypeEnum.BALANCE_TYPE_ADD_PER_OFFLINEMAF]: 'BALANCE_TYPE_ADD_PER_OFFLINEMAF',
  [BalanceTypeEnum.BALANCE_TYPE_HRS_ACHIEVEMENT_BASED]: 'BALANCE_TYPE_HRS_ACHIEVEMENT_BASED',
  [BalanceTypeEnum.BALANCE_TYPE_PROFILE_BONUS]: 'BALANCE_TYPE_PROFILE_BONUS',
  [BalanceTypeEnum.BALANCE_TYPE_HRS_GOAL_BASED]: 'BALANCE_TYPE_HRS_GOAL_BASED',
  [BalanceTypeEnum.BALANCE_TYPE_ADD_PER_TYR]: 'BALANCE_TYPE_ADD_PER_TYR',
  [BalanceTypeEnum.BALANCE_HRS_TRANSFER]: 'BALANCE_HRS_TRANSFER',
  [BalanceTypeEnum.BALANCE_TYPE_HRS_INSTALL]: 'BALANCE_TYPE_HRS_INSTALL',
  [BalanceTypeEnum.BALANCE_TYPE_HRS_MILESTONE]: 'BALANCE_TYPE_HRS_MILESTONE',
  [BalanceTypeEnum.BALANCE_TYPE_HRS_RECONCILATION]: 'BALANCE_HRS_RECONCILIATION',
  [BalanceTypeEnum.BALANCE_TYPE_ADD_TRANSFER]: 'BALANCE_TYPE_ADD_TRANSFER',
  [BalanceTypeEnum.BALANCE_TYPE_ADD_HRS_QUEST]: 'BALANCE_HRS_ADD_QUEST',
  [BalanceTypeEnum.BALANCE_TYPE_ADD_PER_MINING]: 'BALANCE_HRS_ADD_PER_MINING',
};

export const positiveBalanceTypes: BalanceTypeEnum[] = [
  BalanceTypeEnum.BALANCE_TYPE_ADD_PER_AD,
  BalanceTypeEnum.BALANCE_TYPE_ADD_PER_TAPJOY,
  BalanceTypeEnum.BALANCE_TYPE_ADD_PER_FYBER,
  BalanceTypeEnum.BALANCE_TYPE_ADD_PER_IRON_SOURCE,
  BalanceTypeEnum.BALANCE_TYPE_ADD_PER_POLLFISH_VIA_SURVEY,
  BalanceTypeEnum.BALANCE_TYPE_ADD_PER_POLLFISH_VIA_OFFERWALL,
  BalanceTypeEnum.BALANCE_TYPE_ADD_PER_REVU_VIA_OFFER,
  BalanceTypeEnum.BALANCE_TYPE_ADD_PER_REVU_VIA_CUSTOMER,
  BalanceTypeEnum.BALANCE_TYPE_ADD_PER_MAX,
  BalanceTypeEnum.BALANCE_TYPE_ADD_PER_ADJOE,
  BalanceTypeEnum.BALANCE_TYPE_ADD_BY_ADMIN,
  BalanceTypeEnum.BALANCE_TYPE_WITHDRAWAL_DECLINED,
  BalanceTypeEnum.BALANCE_TYPE_REFERRAL_BONUS,
  BalanceTypeEnum.BALANCE_TYPE_COMPLETE_PROFILE,
  BalanceTypeEnum.BALANCE_TYPE_DAILY_TOURNAMENT,
  BalanceTypeEnum.BALANCE_TYPE_TEAM_TOURNAMENT,
  BalanceTypeEnum.BALANCE_TYPE_WITHDRAWAL_BONUS,
  BalanceTypeEnum.BALANCE_TYPE_SPIN_THE_WHEEL,
  BalanceTypeEnum.BALANCE_TYPE_ADD_PER_AYE,
  BalanceTypeEnum.BALANCE_TYPE_ADD_PER_ADGEM,
  BalanceTypeEnum.BALANCE_TYPE_ADD_COMPLETED_PER_INBRAIN,
  BalanceTypeEnum.BALANCE_TYPE_ADD_DISQUALIFIED_PER_INBRAIN,
  BalanceTypeEnum.BALANCE_TYPE_ADD_RANK_REWARD_PER_INBRAIN,
  BalanceTypeEnum.BALANCE_TYPE_ADD_PER_TEST_OFFERWALL,
  BalanceTypeEnum.BALANCE_TYPE_ADJOE_BONUS,
  BalanceTypeEnum.BALANCE_TYPE_DOUBLE_BOINTS,
  BalanceTypeEnum.BALANCE_TYPE_ADD_PER_MAF,
  BalanceTypeEnum.BALANCE_TYPE_ADD_PER_OFFERTORO,
  BalanceTypeEnum.BALANCE_TYPE_ADD_PER_ADGATE,
  BalanceTypeEnum.BALANCE_TYPE_ADD_PER_FARLY,
  BalanceTypeEnum.BALANCE_TYPE_ADD_PER_ADBREAK,
  BalanceTypeEnum.BALANCE_TYPE_ADD_PER_BITLABS,
  BalanceTypeEnum.BALANCE_HRS_TRANSFER,
  BalanceTypeEnum.BALANCE_TYPE_HRS_GOAL_BASED,
  BalanceTypeEnum.BALANCE_TYPE_HRS_TIME_BASED,
  BalanceTypeEnum.BALANCE_TYPE_HRS_IAP_BASED,
  BalanceTypeEnum.BALANCE_TYPE_HRS_VIDEO_BASED,
  BalanceTypeEnum.BALANCE_TYPE_HRS_DAILY_REWARD,
  BalanceTypeEnum.BALANCE_TYPE_ADD_PER_APPSAMURAI,
  BalanceTypeEnum.BALANCE_TYPE_ADD_PER_OFFLINEMAF,
  BalanceTypeEnum.BALANCE_TYPE_HRS_ACHIEVEMENT_BASED,
  BalanceTypeEnum.BALANCE_TYPE_ADD_PER_TYR,
  BalanceTypeEnum.BALANCE_TYPE_ADD_TRANSFER,
  BalanceTypeEnum.BALANCE_TYPE_ADD_HRS_QUEST,
  BalanceTypeEnum.BALANCE_TYPE_ADD_PER_MINING,
];

export const negativeBalanceTypes: BalanceTypeEnum[] = [
  BalanceTypeEnum.BALANCE_TYPE_WITHDRAWAL,
  BalanceTypeEnum.BALANCE_TYPE_REDUCE_PER_REVU_VIA_OFFER_REVERTED,
  BalanceTypeEnum.BALANCE_TYPE_REDUCE_PER_REVU_VIA_CUSTOMER_REVERTED,
  BalanceTypeEnum.BALANCE_TYPE_REDUCE_BY_ADMIN,
  BalanceTypeEnum.BALANCE_TYPE_REDUCE_PER_AYE,
];
