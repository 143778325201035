import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../../stores/app';
import { Column, CustomTable } from '../../components/table';
import { EditableField } from '../../core/interfaces/modal.interfaces';
import { EditableFieldType } from '../../core/enum/modals.enum';
import { CreateEditModal } from '../../components/modals/create-edit';
import { Autocomplete, Box, Button, Checkbox, IconButton, TextField } from '@mui/material';
import {
  BffPopUpManagerGetAllRoute,
  BffPopUpManagerUpdateRequest,
} from '@boints/grpc';
import { ListboxComponent } from '../../components/modals';
import { Delete, Add } from '@mui/icons-material';

type PopUp = BffPopUpManagerGetAllRoute.ResponseType['popUps'][number];

export enum PopUpAcknowledgeType {
  automatic = 'AUTOMATIC',
  manualByUser = 'MANUAL_BY_USER',
  manualBySystem = 'MANUAL_BY_SYSTEM',
}

export const PopUpAcknowledgeTypeLabels = {
  [PopUpAcknowledgeType.automatic]: 'Automatic',
  [PopUpAcknowledgeType.manualByUser]: 'Manual by user',
  [PopUpAcknowledgeType.manualBySystem]: 'Manual by system',
}

export enum PopUpName {
  addReferral = 'ADD_REFERRAL',
  rateApp = 'RATE_APP',
}

export enum PopupTriggerType {
  cashoutsMade = 'CASHOUTS_MADE',
  bointsEarned = 'BOINTS_EARNED',
  mcEarned = 'MC_EARNED',
}

const PopUpTriggerLabels = {
  [PopupTriggerType.bointsEarned]: 'Boints earned',
  [PopupTriggerType.cashoutsMade]: 'Cashouts made',
  [PopupTriggerType.mcEarned]: 'MC earned',
}

export const PopUpManager = observer(() => {
  const { popUpManagerStore } = useStores();

  const loadPopUps = () => {
    popUpManagerStore.getAll();
  };

  const updatePopUp = (dto: BffPopUpManagerUpdateRequest['popUp']) => {
    popUpManagerStore.update({ popUp: dto });
  };

  const editablePopUpItems: EditableField[] = [
    {
        label: "ID",
        type: EditableFieldType.string,
        paramName: "id",
        disabled: true,
    },
    {
        label: "name",
        type: EditableFieldType.string,
        paramName: "name",
        disabled: true,
    },
    {
      label: "Acknowledge type",
      type: EditableFieldType.string,
      paramName: "acknowledgeType",
      enumVariants: {
        "Automatic": PopUpAcknowledgeType.automatic,
        "Manual by user": PopUpAcknowledgeType.manualByUser,
        "Manual by system": PopUpAcknowledgeType.manualBySystem,
      },
    },
    {
      label: "Triggers",
      paramName: "triggers",
      type: EditableFieldType.string,
      customComponent: (currValue: PopUp['triggers'], setValue, currItem) => {
        return (
          <Box>
            <h6 style={{ marginTop: '16px' }}>Triggers</h6>
            <Button variant="outlined" onClick={() => setValue([...currValue, { type: '', value: 0, id: Math.random() } as PopUp['triggers'][number]])}><Add />Add trigger&nbsp;</Button>
            {currValue.map((trigger, i) => (
              <Box key={(trigger as any).id}>
                <Box style={{ margin: '8px 0px 16px 0' }} display='flex' justifyContent='space-between'>
                  <p>Trigger #{i + 1}</p>
                  <IconButton color='error' onClick={() => setValue(currValue.filter((v) => (v as any).id !== (trigger as any).id))}><Delete /></IconButton>
                </Box>

                <Autocomplete
                  size='small'
                  sx={{ marginBottom: '16px' }}
                  options={Object.values(PopupTriggerType)}
                  value={trigger.type}
                  ListboxComponent={
                    ListboxComponent as React.ComponentType<
                        React.HTMLAttributes<HTMLElement>
                    >
                  }
                  getOptionLabel={(option) => PopUpTriggerLabels[option] || ''}
                  onChange={(e, value) => {
                    setValue(currValue.map((v) => {
                      if ((v as any).id !== (trigger as any).id) {
                        return v;
                      }

                      return {
                        ...v,
                        type: value,
                      };
                    }));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Trigger Type"
                    />
                  )}
                />
                
                <TextField
                  label="Trigger value"
                  type="number"
                  value={trigger.value}
                  onChange={(e) => {
                    setValue(currValue.map((v) => {
                      if ((v as any).id !== (trigger as any).id) {
                        return v;
                      }

                      return {
                        ...v,
                        value: Number(e.target.value),
                      };
                    }));
                  }}
                />

                <hr style={{ margin: '16px 0' }}/>
              </Box>
            ))}
          </Box>
        );
      },
    }
  ];

  const columns: Column[] = [
    {
      id: 'id',
      label: 'ID',
      alignContent: 'start',
      minWidth: 50,
    },
    {
      id: 'name',
      label: 'Name',
      minWidth: 300,
    },
    {
      id: 'acknowledgeType',
      label: 'Acknowledge type',
      minWidth: 300,
      component: (row) => <div>{PopUpAcknowledgeTypeLabels[row.acknowledgeType]}</div>,
    },
    {
      id: '',
      label: '',
      minWidth: 20,
      component: (item: BffPopUpManagerUpdateRequest['popUp']) => (<div>
        <CreateEditModal
          btnLabel='Notification Template'
          dialogName="Notification Template"
          currItem={item}
          editableFields={editablePopUpItems}
          acceptAction={updatePopUp}
        />
      </div>)
    },
  ];

  return (
    <CustomTable
      columns={columns}
      data={popUpManagerStore.popUps}
      isSortEnabled={false}
      requestAction={loadPopUps}
      isPaginationShow={false}
    />
  );
});
