import {makeAutoObservable} from 'mobx';
import {RpcError} from 'grpc-web';
import moment from 'moment';
import {AppStore} from '../app';
import {convertKeysToLowerCase} from '../../core';
import {
  RequestGetTicketDto,
  ResponseTicketDto,
  RequestUpdateTicketDto,
} from '../../proto/cashout_pb';
import { CashoutTicketGetRoute, CashoutTicketUpdateRoute } from '@boints/grpc';
import { WebSocketService } from '../../services/transport';
import { UserQualityCashoutStatus } from '@boints/types';

export const networks: Record<string, string> = {
  'BTC': 'Bitcoin',
}

export const fraudStatusKeys: Record<string, string> = {
  1: 'Pass it',
  2: 'Need to check',
  3: 'Suspicious ticket',
  6: 'Suspicious ticket',
};

export const donateMethodKeys: Record<string, string> = {
  1: '-',
  2: 'Red cross',
  3: 'World Vision',
};

export const getUQMarkerStatusDescription = (status: number) => {
  switch (status) {
    case UserQualityCashoutStatus.PASSED:
      return `'Allow player to cashout' requirements are met`;
    case UserQualityCashoutStatus.OFFLINE:
      return `'Allow player to cashout' check is turned off`;
    case UserQualityCashoutStatus.FAILED:
      return `'Allow player to cashout' requirements are not met`;
  }
}

export class RequestGetTicketCsvDto {
  constructor(
    public startDate: number,
    public endDate: number,
    public accesstoken: string
  ) {}
}


export class TicketStore {
  public error: RpcError | null = null;

  public tickets: ResponseTicketDto.AsObject = {
    dataList: [],
    totalcount: 0,
    pagenumber: 1,
    amountperpage: 10,
  };

  public ticketsCSV: ResponseTicketDto.AsObject = {
    dataList: [],
    totalcount: 0,
    pagenumber: 1,
    amountperpage: 10,
  };

  constructor(private rootStore: AppStore) {
    makeAutoObservable(this);
  }

  *getTickets(dto: RequestGetTicketDto.AsObject) {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      const { amountperpage, order, orderby, pagenumber, search } = dto;
      const { amountPerPage, data, pageNumber, totalCount }: CashoutTicketGetRoute.ResponseType = yield WebSocketService
        .sendRequest<CashoutTicketGetRoute.ResponseType>({
          method: 'cashout_ticketGet',
          data: {
            order,
            search: search,
            orderBy: orderby,
            amountPerPage: amountperpage,
            pageNumber: pagenumber,
          }
        });

      this.tickets.amountperpage = amountPerPage;
      this.tickets.pagenumber = pageNumber;
      this.tickets.totalcount = totalCount;

      this.tickets.dataList = data?.map((v) => {
        return convertKeysToLowerCase(v);
      });
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }

  *getTicketsCSV(dto: RequestGetTicketCsvDto) {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      const { amountPerPage, data, pageNumber, totalCount }: CashoutTicketGetRoute.ResponseType = yield WebSocketService
      .sendRequest<CashoutTicketGetRoute.ResponseType>({
        method: 'cashout_ticketGet',
        data: {
          timeFrom: dto.startDate,
          timeTo: dto.endDate,
        }
      });

      this.ticketsCSV.amountperpage = amountPerPage;
      this.ticketsCSV.pagenumber = pageNumber;
      this.ticketsCSV.totalcount = totalCount;
      
      this.ticketsCSV.dataList = data.map((item) => {
        let createdate;
        if (Number(item.createDate) !== 0) {
          createdate = moment(+item.createDate).format(
            'MM/DD/YYYY, HH:mm:ss'
          );
        } else {
          createdate = '-';
        }

        // order in this response affects the order in the csv
        return {
          id: item.id,
          userid: item.userId,
          amount: item.amount,
          currency: item.currency,
          status: item.status,
          method: item.method,
          email: item.email,
          fraudcheck: fraudStatusKeys[item.fraudCheck] as any,
          userqualitycashoutstatus: getUQMarkerStatusDescription(item.userQualityCashoutStatus) as any,
          donate: item.donate,
          donatemethod: donateMethodKeys[item.donateMethod] as any,
          batchid: item.batchId,
          userip: item.userIp,
          ipfraudscore: item.ipFraudScore,
          emailfraudscore: item.emailFraudScore,
          ipcountrycode: item.ipCountryCode,
          version: item.version,
          createdate: createdate,
          updatedate: item.updateDate,
          isbot: item.isBOT,
          isvpn: item.isVPN,
          emailuseractivity: item.emailUserActivity,
          appintegrity: item.playIntegrityInfo?.appIntegrity,
          accountdetails: item.playIntegrityInfo?.accountDetails,
          deviceintegrity: item.playIntegrityInfo?.deviceIntegrity,
          fraudstatusreason: item.fraudStatusReason || '-',
          fullName: item.fullName || '-',
          address: item.address || '',
          network: networks[item.network] || item.network || '',
        };
      });
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }

  *changeTicketStatus(dto: RequestUpdateTicketDto.AsObject) {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      const response: CashoutTicketUpdateRoute.ResponseType = yield WebSocketService
        .sendRequest<CashoutTicketUpdateRoute.ResponseType>({
          method: 'cashout_ticketUpdate',
          data: {
            status: dto.status,
            ticketId: dto.ticketid,
          }
        });

      this.tickets.dataList = this.tickets.dataList.map((item) => {
        if (item.id === response.id) {
          return {
            ...item,
            status: response.status,
          };
        }

        return item;
      });
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }
}
