import * as React from 'react';
import {Navigate, NavLink, Outlet} from 'react-router-dom';
import {observer} from 'mobx-react';
import {
  Box,
  Drawer,
  Divider,
  IconButton,
  Typography,
  Toolbar,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  ListItem,
  List,
  AppBar,
  Switch,
  FormControlLabel,
} from '@mui/material';
import {
  PeopleAlt,
  Block,
  SportsKabaddi,
  Schedule,
  Settings,
  VerifiedUser,
  ConfirmationNumber,
  MonetizationOn,
  PersonAddDisabled,
  PersonAdd,
  Assessment,
  ExitToApp,
  Menu,
  PriceCheck,
  LocalAtm,
  Rule,
  AttachMoney,
  NotificationAdd,
  RecentActors,
  SettingsApplications,
  PushPin,
  StarHalf,
} from '@mui/icons-material';
import {useStores} from '../../stores/app';
import {getDrawerStyle} from './dashboard.styles';
import {Loading} from './loading';
import clsx from 'clsx';

const drawerWidth = 240;

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

interface MenuItem {
  title: string;
  to: string;
  icon: JSX.Element;
}

export const DashboardLayout = observer((props: Props) => {
  const { authStore, themeStore, isLoading} = useStores();
  const {window} = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const classes = getDrawerStyle();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const navMenu: MenuItem[] = [
    {
      title: 'Users',
      to: '/dashboard/users',
      icon: <PeopleAlt />,
    },
    {
      title: 'Banned users',
      to: '/dashboard/banned-users',
      icon: <Block />,
    },
    {
      title: 'Season',
      to: '/dashboard/season',
      icon: <SportsKabaddi />,
    },
    {
      title: 'Double boints',
      to: '/dashboard/double-boints',
      icon: <Schedule />,
    },
    {
      title: 'Settings',
      to: '/dashboard/settings',
      icon: <Settings />,
    },
    {
      title: 'Withdrawal Tickets',
      to: '/dashboard/withdrawal-tickets',
      icon: <ConfirmationNumber />,
    },
    {
      title: 'Transactions',
      to: '/dashboard/transactions',
      icon: <MonetizationOn />,
    },
    {
      title: 'Black list',
      to: '/dashboard/blacklist',
      icon: <PersonAddDisabled />,
    },
    {
      title: 'White list',
      to: '/dashboard/whitelist',
      icon: <PersonAdd />,
    },
    {
      title: 'Daily report',
      to: '/dashboard/daily-report',
      icon: <Assessment />,
    },
    {
      title: 'Rewards',
      to: '/dashboard/rewards',
      icon: <PriceCheck />,
    },
    {
      title: '(MC) Patterns',
      to: '/dashboard/motivational-currency',
      icon: <LocalAtm />
    },
    {
      title: '(MCV2) Config',
      to: '/dashboard/motivational-currency-v2',
      icon: <LocalAtm />
    },
    {
      title: '(MCV2) Stats',
      to: '/dashboard/mc-users-stats',
      icon: <RecentActors />
    },
    {
      title: 'User Quality',
      to: '/dashboard/user-quality',
      icon: <VerifiedUser />
    },
    {
      title: 'Daily Cash Goal',
      to: '/dashboard/daily-goal',
      icon: <AttachMoney />
    },
    {
      title: 'Daily Coins Goal',
      to: '/dashboard/daily-coins-goal',
      icon: <AttachMoney />
    },
    {
      title: 'Feature settings',
      to: '/dashboard/feature-toggle',
      icon: <Rule />
    },
    {
      title: 'Notifications',
      to: '/dashboard/notifications',
      icon: <NotificationAdd />
    },
    {
      title: 'Frontend Settings',
      to: '/dashboard/frontend-settings',
      icon: <SettingsApplications />,
    },
    {
      title: 'Pop-up Manager',
      to: '/dashboard/pop-up-manager',
      icon: <PushPin />,
    },
    {
      title: 'User Exp',
      to: '/dashboard/user-exp',
      icon: <StarHalf />,
    },
  ];

  const drawer = (
    <Box className={classes.wrapper}>
      <Toolbar>
        <Box className={classes.userInfo}>
          <Typography> {authStore.userName}</Typography>
          <IconButton onClick={() => authStore.logout()}>
            <ExitToApp />
          </IconButton>
        </Box>
      </Toolbar>
      <Divider />
      <Box className={classes.navSection}>
        <List className={classes.list}>
          {navMenu.map(({title, icon, to}) => (
            <ListItem style={{ animation: `$pulse 2s infinite` }} key={to} disablePadding>
              <NavLink
                className={({isActive}) => clsx(
                  isActive ? classes.listItemActive : classes.listItem,
                )}
                to={to}
              >
                <ListItemButton>
                  <ListItemIcon className={classes.listItemIcon}>
                    {icon}
                  </ListItemIcon>
                  <ListItemText primary={title} />
                </ListItemButton>
              </NavLink>
            </ListItem>
          ))}
        </List>
        <FormControlLabel
          className={classes.themeSwitcher}
          control={
            <Switch
              color="primary"
              checked={themeStore.darkMode}
              onChange={() => themeStore.switchMode()}
              name="checkedA"
            />
          }
          label="Dark mode"
        />
      </Box>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  if (!authStore.isLoggedIn) {
    return <Navigate to="/login" />;
  }

  return (
    <>
      <Box sx={{display: 'flex'}}>
        <AppBar
          position="fixed"
          sx={{
            width: {sm: `calc(100% - ${drawerWidth}px)`},
            ml: {sm: `${drawerWidth}px`},
            display: {sm: 'none'},
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{mr: 2, display: {sm: 'none'}}}
            >
              <Menu />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{width: {sm: drawerWidth}, flexShrink: {sm: 0}}}
          aria-label="mailbox folders"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: {xs: 'block', sm: 'none'},
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: {xs: 'none', sm: 'block'},
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
                width: drawerWidth,
              },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            width: {sm: `calc(100% - ${drawerWidth}px)`},
            pt: {xs: '56px', sm: 0},
          }}
        >
          <Outlet />
        </Box>
      </Box>
      <Loading isLoading={isLoading} />
    </>
  );
});
