import { observer } from 'mobx-react';
import { useState, useEffect, useCallback } from 'react';
import { useStores } from '../../stores/app';
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogActions,
  Alert,
} from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import { GetSettingsWithPaginationResponse } from '../../proto/settings_pb';

const USER_EXP_SETTINGS_KEY = 'USER_LEVEL_TO_EXP';

type UserLevelToExp = Record<string, number>;

export const UserExp = observer(() => {
  const { settingsStore, notificationStore } = useStores();
  const [userExp, setUserExp] = useState<GetSettingsWithPaginationResponse.AsObject['settingsList'][number]>();
  const [levels, setLevels] = useState<UserLevelToExp>({});
  const [newLevel, setNewLevel] = useState('');
  const [newExperience, setNewExperience] = useState(0);
  const [openSaveDialog, setOpenSaveDialog] = useState(false);

  const [editLevel, setEditLevel] = useState(null);
  const [editExperience, setEditExperience] = useState(0);

  useEffect(() => {
    loadSettings();
    const userExp = settingsStore.settings.settingsList.find(item => item.key === USER_EXP_SETTINGS_KEY);
    if (userExp) {
      setUserExp(userExp);
      setLevels(JSON.parse(userExp.value));
    }
  }, []);

  useEffect(() => {
    const userExp = settingsStore.settings.settingsList.find(item => item.key === USER_EXP_SETTINGS_KEY);
    if (userExp) {
      setUserExp(userExp);
      setLevels(JSON.parse(userExp.value));
    }
  }, [settingsStore.settings.settingsList]);

  const loadSettings = () => {
    settingsStore.getSettings({ microservice: 'offerwall', accesstoken: '' });
  };

  const handleAdd = () => {
    if (levels[newLevel]) {
      notificationStore.enqueueSnackBar({
        variant: 'warning',
        message: 'Level already exists',
      });
    }

    if (newLevel && newExperience && !isNaN(newExperience)) {
      setLevels(prev => ({
        ...prev,
        [newLevel]: Number(newExperience > 0 ? newExperience : 0)
      }));
      setNewLevel('');
      setNewExperience(0);
    }
  };

  const handleDelete = (level) => {
    const newLevels = { ...levels };
    delete newLevels[level];
    setLevels(newLevels);
  };
  
  const handleEditStart = (level, experience) => {
    setEditLevel(level);
    setEditExperience(experience);
  };

  const handleEditSave = (level) => {
    if (!isNaN(editExperience)) {
      setLevels(prev => ({
        ...prev,
        [level]: Number(editExperience > 0 ? editExperience : 0)
      }));
      setEditLevel(null);
      setEditExperience(0);
    }
  };
  
  const handleSave = useCallback(() => {
    if (!userExp) {
      return;
    }

    settingsStore.updateSettings({
      accesstoken: '',
      id: userExp.id,
      key: userExp.key,
      value: JSON.stringify(levels),
      description: userExp.description,
      dictionarytype: userExp.dictionarytype,
    });

    setOpenSaveDialog(false);
  }, [levels]);

  function SaveDialog() {
    return (
      <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
        <Button onClick={() => setOpenSaveDialog(true)}>Save</Button>
        <Dialog onClose={() => setOpenSaveDialog(false)} open={openSaveDialog}>
          <DialogTitle>Do you want to save your changes?</DialogTitle>
          <DialogActions>
            <Button onClick={handleSave}>Submit</Button>
            <Button onClick={() => setOpenSaveDialog(false)}>Cancel</Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  }

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Box sx={{ 
          mb: 3, 
          display: 'flex', 
          justifyContent: 'center', 
          gap: 2 
        }}>
        <TextField
          label="Level"
          value={newLevel}
          onChange={(e) => setNewLevel(e.target.value)}
          sx={{ mr: 2 }}
          size="small"
        />
        <TextField
          label="Experience Required"
          value={newExperience}
          onChange={(e) => setNewExperience(+e.target.value)}
          sx={{ mr: 2 }}
          size="small"
          type="number"
          error={newExperience < 0}
        />
        <Button 
          variant="contained" 
          onClick={handleAdd}
          disabled={!newLevel || !newExperience}
        >
          Add Level
        </Button>
      </Box>

      {Object.keys(levels).length === 0 && (
        <Alert severity="info">
          No levels found
        </Alert>
      )}
      {/* Levels Table */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align='center' sx={{ width: '33%' }}>Level</TableCell>
              <TableCell align='center' sx={{ width: '33%' }}>Experience Required</TableCell>
              <TableCell align='center' sx={{ width: '33%' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(levels).map(([level, experience]) => (
              <TableRow key={level}>
                <TableCell align='center'>{level}</TableCell>
                <TableCell align='center'>
                  {editLevel === level ? (
                    <TextField
                      value={editExperience}
                      onChange={(e) => setEditExperience(+e.target.value)}
                      size="small"
                      type="number"
                      variant="outlined"
                      error={editExperience < 0}
                    />
                  ) : (
                    experience
                  )}
                </TableCell>
                <TableCell align='center'>
                  {editLevel === level ? (
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => handleEditSave(level)}
                    >
                      Save
                    </Button>
                  ) : (
                    <>
                      <IconButton
                        onClick={() => handleEditStart(level, experience)}
                      >
                        <Edit color='primary'/>
                      </IconButton>
                      <IconButton
                        onClick={() => handleDelete(level)}
                      >
                        <Delete color="error" />
                      </IconButton>
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {<SaveDialog />}
    </Container>
  );
});
