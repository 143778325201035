import EventEmitter from "events";
import { v4 as uuidv4 } from 'uuid';
import {
  ContractType, NotificationsContract, RequestContract, ResponseContract,
} from "../../core/interfaces/contract.interfaces";
import { ResponseStatus } from '../../core/interfaces/transport.interfaces';
import { BFF_URL, IS_LOCALHOST, IS_STAGE } from "../../core";
import { broadcasts } from "./broadcasts";
import { requests } from ".";
import { semaphore } from "../../core/utils/semaphore/decorator";

export interface RequestParams<T> {
  data: T;
  method: keyof typeof requests;
}

type ResolveType = (value: unknown | PromiseLike<unknown>) => void;

export const WebSocketService = new (class {
  public webSocket: WebSocket;
  private readonly requests: Map<string, { resolve: ResolveType, reject: (value: Error) => void }> = new Map();
  private readonly requestsQueue: Map<string, { request: RequestContract<unknown, unknown>, resolve: ResolveType, reject: (value: Error) => void }> = new Map();
  public readonly notificationEmitter: EventEmitter = new EventEmitter();

  constructor() {
    this.webSocket = new WebSocket(BFF_URL);

    this.initListeners();
  }

  public send<REQUEST = unknown, RESPONSE = unknown>(
    request: RequestContract<REQUEST, RESPONSE>,
  ): Promise<RESPONSE> {
    return new Promise((resolve, reject) => {
      if (this.webSocket.readyState === this.webSocket.OPEN) {
        this.webSocket.send(JSON.stringify(request));
        this.requests.set(request.id, { resolve: resolve as ResolveType, reject });
      } else {
        this.requestsQueue.set(request.id, { request, resolve: resolve as ResolveType, reject });
      }
    });
  }

  private onClose() {
    this.webSocket = new WebSocket(BFF_URL);

    this.initListeners();
  }

  private initListeners() {
    this.webSocket.onopen = this.onOpen.bind(this);

    this.webSocket.onclose = this.onClose.bind(this);

    this.webSocket.onmessage = (e) => this.onMessage(e);
  }

  private onOpen() {
    console.log('WebSocket connection opened');

    this.loginByToken();

    this.requestsQueue.forEach(({ request, resolve, reject }, key) => {
      try {
        this.webSocket.send(JSON.stringify(request));
        this.requests.set(request.id, { resolve, reject });
      } catch (e) {
        reject(e as Error);
      }

      this.requestsQueue.delete(key);
    });
  }

  private onMessage(e: MessageEvent) {
    const contract = JSON.parse(e.data as string) as ResponseContract | NotificationsContract;

    if (contract.type === ContractType.notification) {
      this.notificationEmitter.emit(contract.method, contract.data);
      return;
    }

    const { resolve, reject } = (this.requests.get(contract.id) || {});

    if (!resolve || !reject) {
      return;
    }

    if (contract.response.code === ResponseStatus.OK) {
      resolve(contract.response.data);
    } else {
      console.log(contract.response);
      reject(new Error(contract.response.message));
    }

    this.requests.delete(contract.id);
  }

  async subscribeToBroadcast<T>({ type, cb }: { type: typeof broadcasts[number], cb: (value: T) => void }) {
    this.notificationEmitter.on(type, cb);
  }

  // @ts-ignore
  @semaphore()
  async sendRequest<RESPONSE = unknown, REQUEST = unknown>(request: RequestParams<Omit<REQUEST, 'accessToken'>>): Promise<RESPONSE> {
    const id = uuidv4();
    const { microserviceName, serviceName, method } = requests[request.method];

    if (IS_STAGE || IS_LOCALHOST) {
      console.log('REQUEST', request.method, request);
    }

    const response = await this.send<REQUEST & { accessToken: string }, RESPONSE>({
      id,
      type: ContractType.request,
      microserviceName,
      serviceName,
      method,
      request: {
        params: {
          ...request.data, accessToken: localStorage.getItem('accessToken') || undefined,
        } as REQUEST & { accessToken: string },
      },
      metadata: {
        authInfo: {},
      },
    });

    if (IS_STAGE || IS_LOCALHOST) {
      console.log('RESPONSE', request.method, response);
    }

    return response;
  }

  private loginByToken() {
    const accessToken = localStorage.getItem('accessToken');

    if (accessToken) {
      this.send({
        id: uuidv4(),
        type: ContractType.request,
        microserviceName: 'bff',
        serviceName: 'AuthService',
        method: 'loginByToken',
        request: {
          params: {
            accessToken: accessToken,
          },
        },
        metadata: {
          authInfo: {},
        },
      });
    }
  }
})();