import {
  Box, Checkbox,
  InputLabel, ToggleButtonGroup,
  ToggleButton,
  Tooltip,
  FormControlLabel,
  Divider,
} from '@mui/material';
import {
  GppBad, GppGood, GppMaybe, DesktopWindows,
  SignalCellularAlt1Bar,
  SignalCellularAlt2Bar,
  SignalCellularAlt,
  SentimentVeryDissatisfied,
  SentimentDissatisfied,
  SentimentVerySatisfied,
} from '@mui/icons-material';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { Column, CustomTable } from '../../components/table';
import { useStores } from '../../stores/app';
import { CreateEditModal } from '../../components/modals/create-edit';
import { EditableField } from '../../core/interfaces/modal.interfaces';
import { EditableFieldType } from '../../core/enum/modals.enum';
import { SettingsEntry } from '../../proto/userQuality_pb';
import { getWithdrawalTicketsStyle } from './user-quality.styles';

const countries = {
  USA: 'US',
  Canada: 'CA',
  Ukraine: 'UA',
  Poland: 'PL',
  Belarus: 'BY',
  UnitedKingdom: 'GB',
  Australia: 'AU',
  Germany: 'DE',
  France: 'FR',
  Japan: 'JP',
  China: 'CN',
  India: 'IN',
  Brazil: 'BR',
  Mexico: 'MX',
  Russia: 'RU',
  SouthAfrica: 'ZA',
  Argentina: 'AR',
  Spain: 'ES',
  Italy: 'IT',
  Sweden: 'SE',
  Norway: 'NO',
  Denmark: 'DK',
  Netherlands: 'NL',
  Switzerland: 'CH',
  Belgium: 'BE',
  Greece: 'GR',
  Portugal: 'PT',
  NewZealand: 'NZ',
  Singapore: 'SG',
  SouthKorea: 'KR',
  Israel: 'IL',
  SaudiArabia: 'SA',
  UAE: 'AE',
  Qatar: 'QA',
  Romain: 'RO',
};

const checks = {
  legitimate: 'Count player as valid',
  login: 'Allow player to login',
  cashout: 'Allow player to cashout',
  HRS: 'Allow player to see HRS section',
  tapjoy: 'Allow player to see Tapjoy section',
  fyber: 'Allow player to see Fyber section',
  ironSource: 'Allow player to see IronSource section',
  pollfish: 'Allow player to see Pollfish section',
  revU: 'Allow player to see RevU section',
  max: 'Allow player to see Max section',
  adjoe: 'Allow player to see Adjoe section',
  aye: 'Allow player to see Aye section',
  adGem: 'Allow player to see AdGem section',
  inBrain: 'Allow player to see InBrain section',
  adBreak: 'Allow player to see AdBreak section',
  adGate: 'Allow player to see AdGate section',
  farly: 'Allow player to see Farly section',
  maf: 'Allow player to see MAF section',
  offertoro: 'Allow player to see Offertoro section',
};

export const UserQuality = observer(() => {
  const { userQualityStore } = useStores();
  const classes = getWithdrawalTicketsStyle()

  const updateSettings = (item: any) => {
    userQualityStore.updateSettings({
      accesstoken: '',
      settingsList: [item],
    });
  };

  const columns: Column[] = [
    {
      id: 'checks',
      label: 'Checks',
      minWidth: 20,
      component: (item: SettingsEntry.AsObject) => (<div>
        {checks[item.type] || item.type}
      </div>),
    },
    {
      id: 'enabled',
      label: 'Enabled',
      minWidth: 100,
      component: (item: SettingsEntry.AsObject) => (<div>
        <Checkbox
          checked={!item.disabled}
          onChange={(e) => {
            userQualityStore.updateSettings({
              accesstoken: '',
              settingsList: [{ ...item, disabled: !e.target.checked }],
            });
          }}
        />
      </div>),
    },
    {
      id: 'appIntegrity',
      label: 'Allowed App Integrity Statuses',
      minWidth: 100,
      className: classes.widthHeight100,
      component: (item: SettingsEntry.AsObject) => (<Box>
        <ToggleButtonGroup
          value={item.disabled || !item.isgoogleplayintegrityenabled ? undefined : JSON.stringify(item.appintegrityList)}
          aria-label="App integrity"
          disabled={item.disabled || !item.isgoogleplayintegrityenabled}
        >
          <ToggleButton
            value='[{"verdict":"PLAY_RECOGNIZED","value":true},{"verdict":"UNRECOGNIZED_VERSION","value":true},{"verdict":"UNEVALUATED","value":true}]'
            key="3"
          >
            <Tooltip title='PLAY_RECOGNIZED, UNEVALUATED, UNRECOGNIZED_VERSION'>
              <GppBad />
            </Tooltip>
          </ToggleButton>
          <ToggleButton
            value='[{"verdict":"PLAY_RECOGNIZED","value":true},{"verdict":"UNRECOGNIZED_VERSION","value":true},{"verdict":"UNEVALUATED","value":false}]'
            key="2"
          >
            <Tooltip title='PLAY_RECOGNIZED, UNEVALUATED'>
              <GppMaybe />
            </Tooltip>
          </ToggleButton>
          <ToggleButton
            value='[{"verdict":"PLAY_RECOGNIZED","value":true},{"verdict":"UNRECOGNIZED_VERSION","value":false},{"verdict":"UNEVALUATED","value":false}]'
            key="1"
          >
            <Tooltip title='PLAY_RECOGNIZED'>
              <GppGood />
            </Tooltip>
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>),
    },
    {
      id: 'deviceIntegrity',
      label: 'Allowed Device Integrity Statuses',
      minWidth: 100,
      component: (item: SettingsEntry.AsObject) => (<Box>
        <ToggleButtonGroup
          value={item.disabled || !item.isgoogleplayintegrityenabled ? undefined : JSON.stringify(item.deviceintegrityList)}
          aria-label="Device Integrity"
          disabled={item.disabled || !item.isgoogleplayintegrityenabled}
        >
          <ToggleButton
            value='[{"verdict":"MEETS_STRONG_INTEGRITY","value":true},{"verdict":"MEETS_DEVICE_INTEGRITY","value":true},{"verdict":"MEETS_BASIC_INTEGRITY","value":true},{"verdict":"NO_VALUE","value":true}]'
            key="4"
          >
            <Tooltip title='MEETS_STRONG_INTEGRITY, MEETS_DEVICE_INTEGRITY, MEETS_BASIC_INTEGRITY, NO_VALUE'>
              <DesktopWindows />
            </Tooltip>
          </ToggleButton>
          <ToggleButton
            value='[{"verdict":"MEETS_STRONG_INTEGRITY","value":true},{"verdict":"MEETS_DEVICE_INTEGRITY","value":true},{"verdict":"MEETS_BASIC_INTEGRITY","value":true},{"verdict":"NO_VALUE","value":false}]'
            key="3"
          >
            <Tooltip title='MEETS_STRONG_INTEGRITY, MEETS_DEVICE_INTEGRITY, MEETS_BASIC_INTEGRITY'>
              <SignalCellularAlt1Bar />
            </Tooltip>
          </ToggleButton>
          <ToggleButton
            value='[{"verdict":"MEETS_STRONG_INTEGRITY","value":true},{"verdict":"MEETS_DEVICE_INTEGRITY","value":true},{"verdict":"MEETS_BASIC_INTEGRITY","value":false},{"verdict":"NO_VALUE","value":false}]'
            key="2"
          >
            <Tooltip title='MEETS_STRONG_INTEGRITY, MEETS_DEVICE_INTEGRITY'>
              <SignalCellularAlt2Bar />
            </Tooltip>
          </ToggleButton>
          <ToggleButton
            value='[{"verdict":"MEETS_STRONG_INTEGRITY","value":true},{"verdict":"MEETS_DEVICE_INTEGRITY","value":false},{"verdict":"MEETS_BASIC_INTEGRITY","value":false},{"verdict":"NO_VALUE","value":false}]'
            key="1"
          >
            <Tooltip title='MEETS_STRONG_INTEGRITY'>
              <SignalCellularAlt />
            </Tooltip>
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>),
    },
    {
      id: 'accoutDetails',
      label: 'Allowed Account Details Statuses',
      minWidth: 100,
      component: (item: SettingsEntry.AsObject) => (<Box>
        <ToggleButtonGroup
          value={item.disabled || !item.isgoogleplayintegrityenabled ? undefined : JSON.stringify(item.accountdetailsList)}
          aria-label="Account Details"
          disabled={item.disabled || !item.isgoogleplayintegrityenabled}
        >
          <ToggleButton
            value='[{"verdict":"LICENSED","value":true},{"verdict":"UNEVALUATED","value":true},{"verdict":"UNLICENSED","value":true}]'
            key="3"
          >
            <Tooltip title='LICENSED, UNLICENSED, UNEVALUATED'>
              <SentimentVeryDissatisfied />
            </Tooltip>
          </ToggleButton>
          <ToggleButton
            value='[{"verdict":"LICENSED","value":true},{"verdict":"UNEVALUATED","value":true},{"verdict":"UNLICENSED","value":false}]'
            key="2"
          >
            <Tooltip title='LICENSED, UNEVALUATED'>
              <SentimentDissatisfied />
            </Tooltip>
          </ToggleButton>
          <ToggleButton
            value='[{"verdict":"LICENSED","value":true},{"verdict":"UNEVALUATED","value":false},{"verdict":"UNLICENSED","value":false}]'
            key="1"
          >
            <Tooltip title='LICENSED'>
              <SentimentVerySatisfied />
            </Tooltip>
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>),
    },
    {
      id: 'fraudScore',
      label: 'Max Allowed FS',
      minWidth: 100,
      component: (item: SettingsEntry.AsObject) => (
      <Box
        height={'55px'}
        width={'55px'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
        color={item.disabled || !item.isfingerprintenabled ? '#bdbdbd' : 'inherit'}
      >
        {item.fingerprintinfo?.fraudscore}
      </Box>),
    },
    {
      id: 'countries',
      label: 'Allowed Countries',
      minWidth: 100,
      component: (item: SettingsEntry.AsObject) => (
      <Box
        height={'55px'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
        color={item.disabled || !item.isfingerprintenabled ? '#bdbdbd' : 'inherit'}
      >
        {item.fingerprintinfo?.countriesList.join(', ')}
      </Box>),
    },
    {
      id: 'emulator',
      label: 'Emulator Filter',
      minWidth: 100,
      component: (item: SettingsEntry.AsObject) => (
      <Box
        height={'55px'}
        width={'55px'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Checkbox
          disabled={item.disabled || !item.isfingerprintenabled}
          checked={!item.fingerprintinfo?.emulator}
        />
      </Box>),
    },
    {
      id: 'vpn',
      label: 'Vpn Filter',
      minWidth: 100,
      component: (item: SettingsEntry.AsObject) => (
      <Box
        height={'55px'}
        width={'55px'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Checkbox
          disabled={item.disabled || !item.isfingerprintenabled}
          checked={!item.fingerprintinfo?.vpn}
        />
      </Box>),
    },
    {
      id: '',
      label: '',
      minWidth: 20,
      component: (item: SettingsEntry.AsObject) => (<div>
        <CreateEditModal
          btnLabel='Settings'
          dialogName={checks[item.type] || item.type}
          currItem={item}
          editableFields={editableSettingsItems}
          acceptAction={updateSettings}
        />
      </div>),
    },
  ];

  const editableSettingsItems: EditableField[] = [
    {
      label: "Check",
      type: EditableFieldType.string,
      paramName: "type",
      disabled: true,
      customComponent: (currValue) => (<div>
        {checks[currValue] || currValue}
      </div>),
    },
    {
      label: "Enable Google Play Integrity",
      type: EditableFieldType.checkbox,
      paramName: "isgoogleplayintegrityenabled",
      customComponent: (currValue, setValue) => {
        return (
          <Box key='Enable Google Play Integrity'>
            <FormControlLabel
              control={
                <Checkbox
                  checked={currValue}
                  onChange={(e) => {
                    setValue(e.target.checked);
                  }}
                />
              } label="Enable Google Play Integrity"
            />
          </Box>
        );
      },
    },
    {
      label: "App Integrity",
      type: EditableFieldType.string,
      paramName: "appintegrityList",
      customComponent: (currValue, setValue) => {
        return (
          <Box key="appintegrityList" className={classes.topContainer}>
            <InputLabel id="app-integrity-label">App Integrity</InputLabel>
            <ToggleButtonGroup
              value={JSON.stringify(currValue)}
              aria-label="App integrity"
              onChange={(e, v) => {
                if (v) {
                  setValue(JSON.parse(v));
                }
              }}
              size="large"
              // defaultValue='["UNRECOGNIZED_VERSION","UNEVALUATED","PLAY_RECOGNIZED"]'
              exclusive
            >
              <ToggleButton
                value='[{"verdict":"PLAY_RECOGNIZED","value":true},{"verdict":"UNRECOGNIZED_VERSION","value":true},{"verdict":"UNEVALUATED","value":true}]'
                key="3"
              >
                <Tooltip title='PLAY_RECOGNIZED, UNEVALUATED, UNRECOGNIZED_VERSION'>
                  <GppBad />
                </Tooltip>
              </ToggleButton>
              <ToggleButton
                value='[{"verdict":"PLAY_RECOGNIZED","value":true},{"verdict":"UNRECOGNIZED_VERSION","value":true},{"verdict":"UNEVALUATED","value":false}]'
                key="2"
              >
                <Tooltip title='PLAY_RECOGNIZED, UNEVALUATED'>
                  <GppMaybe />
                </Tooltip>
              </ToggleButton>
              <ToggleButton
                value='[{"verdict":"PLAY_RECOGNIZED","value":true},{"verdict":"UNRECOGNIZED_VERSION","value":false},{"verdict":"UNEVALUATED","value":false}]'
                key="1"
              >
                <Tooltip title='PLAY_RECOGNIZED'>
                  <GppGood />
                </Tooltip>
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        );
      },
    },
    {
      label: "Device Integrity",
      type: EditableFieldType.string,
      paramName: "deviceintegrityList",
      customComponent: (currValue, setValue) => {
        return (
          <Box key="deviceintegrityList" className={classes.middleContainer}>
            <InputLabel id="device-integrity-label">Device Integrity</InputLabel>
            <ToggleButtonGroup
              value={JSON.stringify(currValue)}
              aria-label="Device Integrity"
              onChange={(e, v) => {
                if (v) {
                  setValue(JSON.parse(v));
                }
              }}
              size="large"
              exclusive
            >
              <ToggleButton
                value='[{"verdict":"MEETS_STRONG_INTEGRITY","value":true},{"verdict":"MEETS_DEVICE_INTEGRITY","value":true},{"verdict":"MEETS_BASIC_INTEGRITY","value":true},{"verdict":"NO_VALUE","value":true}]'
                key="4"
              >
                <Tooltip title='MEETS_STRONG_INTEGRITY, MEETS_DEVICE_INTEGRITY, MEETS_BASIC_INTEGRITY, NO_VALUE'>
                  <DesktopWindows />
                </Tooltip>
              </ToggleButton>
              <ToggleButton
                value='[{"verdict":"MEETS_STRONG_INTEGRITY","value":true},{"verdict":"MEETS_DEVICE_INTEGRITY","value":true},{"verdict":"MEETS_BASIC_INTEGRITY","value":true},{"verdict":"NO_VALUE","value":false}]'
                key="3"
              >
                <Tooltip title='MEETS_STRONG_INTEGRITY, MEETS_DEVICE_INTEGRITY, MEETS_BASIC_INTEGRITY'>
                  <SignalCellularAlt1Bar />
                </Tooltip>
              </ToggleButton>
              <ToggleButton
                value='[{"verdict":"MEETS_STRONG_INTEGRITY","value":true},{"verdict":"MEETS_DEVICE_INTEGRITY","value":true},{"verdict":"MEETS_BASIC_INTEGRITY","value":false},{"verdict":"NO_VALUE","value":false}]'
                key="2"
              >
                <Tooltip title='MEETS_STRONG_INTEGRITY, MEETS_DEVICE_INTEGRITY'>
                  <SignalCellularAlt2Bar />
                </Tooltip>
              </ToggleButton>
              <ToggleButton
                value='[{"verdict":"MEETS_STRONG_INTEGRITY","value":true},{"verdict":"MEETS_DEVICE_INTEGRITY","value":false},{"verdict":"MEETS_BASIC_INTEGRITY","value":false},{"verdict":"NO_VALUE","value":false}]'
                key="1"
              >
                <Tooltip title='MEETS_STRONG_INTEGRITY'>
                  <SignalCellularAlt />
                </Tooltip>
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        );
      },
    },
    {
      label: "Account Details",
      type: EditableFieldType.string,
      paramName: "accountdetailsList",
      customComponent: (currValue, setValue) => {
        return (
          <Box key="accountdetailsList" className={classes.bottomContainer}>
            <InputLabel id="account-details-label">Account Details</InputLabel>
            <ToggleButtonGroup
              value={JSON.stringify(currValue)}
              aria-label="Account Details"
              onChange={(e, v) => {
                if (v) {
                  setValue(JSON.parse(v));
                }
              }}
              size="large"
              exclusive
            >
              <ToggleButton
                value='[{"verdict":"LICENSED","value":true},{"verdict":"UNEVALUATED","value":true},{"verdict":"UNLICENSED","value":true}]'
                key="3"
              >
                <Tooltip title='LICENSED, UNLICENSED, UNEVALUATED'>
                  <SentimentVeryDissatisfied />
                </Tooltip>
              </ToggleButton>
              <ToggleButton
                value='[{"verdict":"LICENSED","value":true},{"verdict":"UNEVALUATED","value":true},{"verdict":"UNLICENSED","value":false}]'
                key="2"
              >
                <Tooltip title='LICENSED, UNEVALUATED'>
                  <SentimentDissatisfied />
                </Tooltip>
              </ToggleButton>
              <ToggleButton
                value='[{"verdict":"LICENSED","value":true},{"verdict":"UNEVALUATED","value":false},{"verdict":"UNLICENSED","value":false}]'
                key="1"
              >
                <Tooltip title='LICENSED'>
                  <SentimentVerySatisfied />
                </Tooltip>
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        );
      },
    },
    {
      label: "Enable Fingerprint",
      type: EditableFieldType.checkbox,
      paramName: "isfingerprintenabled",
      customComponent: (currValue, setValue) => {
        return (
          <Box key='Enable Fingerprint' marginTop='10px'>
            <FormControlLabel
              control={
                <Checkbox
                  checked={currValue}
                  onChange={(e) => {
                    setValue(e.target.checked);
                  }}
                />
              } label="Enable Fingerprint"
            />
          </Box>
        );
      },
    },
    {
      label: "Allowed Countries",
      type: EditableFieldType.multipick,
      paramName: "fingerprintinfo.countriesList",
      enumVariants: countries,
      className: classes.topContainer,
    },
    {
      label: "Max Allowed Fraud Score",
      type: EditableFieldType.number,
      paramName: "fingerprintinfo.fraudscore",
      className: classes.middleContainer  ,
    },
    {
      label: "Emulator filter",
      type: EditableFieldType.checkbox,
      paramName: "fingerprintinfo.emulator",
      customComponent: (currValue, setValue) => {
        return (
          <Box key='Emulator filter' className={classes.middleContainer}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!currValue}
                  onChange={(e) => {
                    setValue(!e.target.checked);
                  }}
                />
              } label="Emulator filter"
            />
          </Box>
        );
      },
    },
    {
      label: "Vpn Filter",
      type: EditableFieldType.checkbox,
      paramName: "fingerprintinfo.vpn",
      customComponent: (currValue, setValue) => {
        return (
          <Box key='Vpn filter' className={classes.bottomContainer}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!currValue}
                  onChange={(e) => {
                    setValue(!e.target.checked);
                  }}
                />
              } label="Vpn filter"
            />
          </Box>
        );
      },
    },
  ];

  useEffect(() => {
    userQualityStore.getSettings();
  }, [userQualityStore]);

  return (
    <Box>
      <CustomTable
        data={userQualityStore.settings.settingsList || []}
        requestAction={() => { }}
        columns={columns}
        isPaginationShow={false}
        tableInnerProps={{ "size": "small" }}
        isSortEnabled={false}
        isDataSliced={true}
      />
    </Box>
  );
});
